<template>
  <div>
    <div class="DivBox">
      <p class="PTitle">每年目标指标的维护和更新</p>
    </div>
    <div class="DivBox">
      <p class="PTitle LeftBor">服务介绍：</p>
      <p class="SmallP">
        协助进行目标趋势分析
        <br />
        帮你掌握目标和指标不一样的管理方式
      </p>
      <br />

      <p class="PTitle LeftBor">解决客户的什么问题：</p>
      <p class="SmallP">
        KPI成为负担？
        <br />
        平衡计分卡起不到作用？
        <br />
        目标讨论走过场？
      </p>
      <br />
      <p class="PTitle LeftBor">我们公司的解决方案：</p>
      <p class="SmallP">
        先培训后辅导
        <br />
        与相关责任人一起建立目标趋势分析
        <br />
        共同搭建目标方案
      </p>
      <br />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
@import "../../../../assets/style/FirstProductSon.css";
</style>